import React from "react";
import { Authentication, Execution, FieldMapping, SolutionInstances } from '../../../Connectors/CustomTabs/NetSuite';
import SyncSettings from '../../../Connectors/CustomTabs/ZDP/SyncSettings/SyncSettings';
import { PackageLink } from "../../../Connectors/CustomTabs/SalesforceCRM";
import ConnectionSettings from '../../../Connectors/CustomTabs/Snowflake/ConnectionSettings';
import { Authentication as ZDPAuthentication } from '../../../Connectors/CustomTabs/ZDP/Authentication/Authentication';
import {
  Authentication as WDAuthentication,
  Execution as WDExecution,
  FieldMapping as WDFieldMapping
} from '../../../Connectors/CustomTabs/Workday';
import {
  Authentication as WDBAuthentication,
  Execution as WDBExecution,
  FieldMapping as WDBFieldMapping
} from '../../../Connectors/CustomTabs/WorkdayBilling';
import ZdqBigQueryConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/BigQuery/ZdqBigQueryConnector";
import ZdqBigQueryConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/BigQuery/ZdqBigQueryConnectorStatus";
import ZdqGcpPostgresConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/GcpPostgres/ZdqGcpPostgresConnector";
import ZdqGcpPostgresConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/GcpPostgres/ZdqGcpPostgresConnectorStatus";
import ZdqAwsAuroraPostgresConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsAuroraPostgres/ZdqAwsAuroraPostgresConnector";
import ZdqAwsAuroraPostgresConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsAuroraPostgres/ZdqAwsAuroraPostgresConnectorStatus";
import ZdqAwsRdsPostgresConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsRdsPostgres/ZdqAwsRdsPostgresConnector";
import ZdqAwsRdsPostgresConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsRdsPostgres/ZdqAwsRdsPostgresConnectorStatus";
import ZdqGenericPostgresConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/GenericPostgres/ZdqGenericPostgresConnector";
import ZdqGenericPostgresConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/GenericPostgres/ZdqGenericPostgresConnectorStatus";
import ZdqAuroraMySqlConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/AuroraMySql/ZdqAuroraMySqlConnector";
import ZdqAuroraMySqlConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AuroraMySql/ZdqAuroraMySqlConnectorStatus";
import ZdqGenericMySqlConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/GenericMySql/ZdqGenericMySqlConnector";
import ZdqGenericMySqlConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/GenericMySql/ZdqGenericMySqlConnectorStatus";
import ZdqAwsRedshiftConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsRedshift/ZdqAwsRedshiftConnector";
import ZdqAwsRedshiftConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsRedshift/ZdqAwsRedshiftConnectorStatus";
import ZdqAwsS3Connector from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsS3/ZdqAwsS3Connector";
import ZdqAwsS3ConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsS3/ZdqAwsS3ConnectorStatus";
import ZdqAzureBlobStorageConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/AzureBlobStorage/ZdqAzureBlobStorageConnector";
import ZdqAzureBlobStorageConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AzureBlobStorage/ZdqAzureBlobStorageConnectorStatus";
import ZdqAwsAthenaConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsAthena/ZdqAwsAthenaConnector";
import ZdqAwsAthenaConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/AwsAthena/ZdqAwsAthenaConnectorStatus";
import ZdqGoogleCloudStorageConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/GoogleCloudStorage/ZdqGoogleCloudStorageConnector";
import ZdqGoogleCloudStorageConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/GoogleCloudStorage/ZdqGoogleCloudStorageConnectorStatus";
import ZdqDatabricksConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/Databricks/ZdqDatabricksConnector";
import ZdqDatabricksConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/Databricks/ZdqDatabricksConnectorStatus";
import ZdqSqlServerConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/SqlServer/ZdqSqlServerConnectorStatus";
import ZdqSqlServerConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/SqlServer/ZdqSqlServerConnector";
import TestConnection from "../../../Connectors/CustomTabs/SalesforceCPQ/Connection/TestConnection";
import BasicTemplate from "../../../BasicTemplate/BasicTemplate";
import {
  Destination,
  SAPBillingAuthentication,
  SAPBillingExecution,
  SAPRevenueAuthentication,
  SAPRevenueExecution
} from '../../../Connectors/CustomTabs/SAP';

export interface CustomTabProps {
  name: string; // key string from the JSON payload
  tabDetails: object; // tabDetails contains all the values of the JSON payload set in ui_layout for your custom tab
  locale: string; // locale in Rails I18n format https://guides.rubyonrails.org/i18n.html
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  tenantId: string; // Tenant id in Connect's database for calls to the backend, 32 digit uuid
  customIcon: (tabName: string, icon: string, tooltip: string) => void;
}

export const CustomTab = ({
  name,
  tabDetails,
  locale,
  lastUpdatedBy,
  lastUpdatedDate,
  tenantId,
  customIcon
}: CustomTabProps) => {

  function renderSwitch(name) {
    switch (name) {
      // When adding a new custom tab, create a case that matches the json key name of your desired tab
      // The check here is case sensitive!
      // NetSuite ERP custom tabs: execution, solutionInstances, and fieldMapping
      case 'NetSuiteERP/execution':
        return <Execution tabName={'execution'} inbound={true} newLayout={tabDetails['newLayout']} />;
      case 'NetSuiteERP/solutionInstances':
        return <SolutionInstances tabName={'solutionInstances'} inbound={true} />;
      case 'NetSuiteERP/fieldMapping':
        return <FieldMapping tabName='fieldMapping' inbound={true} />;
      case 'NetSuiteERP/authentication':
      // NetSuite GL custom tabs: authentication, execution, solutionInstances, and fieldMapping
      case 'NetSuiteGL/authentication':
        return (
          <Authentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'NetSuiteGL/execution':
        return <Execution tabName={'execution'} inbound={false} newLayout={tabDetails['newLayout']} />;
      case 'NetSuiteGL/solutionInstances':
        return <SolutionInstances tabName={'solutionInstances'} inbound={false} />;
      case 'NetSuiteGL/fieldMapping':
        return <FieldMapping tabName='fieldMapping' inbound={false} />;
      case 'Workday/authentication':
        return (
          <WDAuthentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'Workday/execution':
        return <WDExecution tabName='execution' newLayout={tabDetails['newLayout']} />;
      case 'Workday/fieldMapping':
        return <WDFieldMapping tabName='fieldMapping' />
      case 'ZDP/syncSettings':
        return <SyncSettings />;
      case 'ZDP/authentication':
        return <ZDPAuthentication />;
      case 'Salesforce/packageLink':
        return <PackageLink
          showAlertIcon={customIcon}
        />;
      case 'SFDC/testConnection':
        return <TestConnection fieldGroups={(tabDetails as any).fieldGroups}/>
      case 'Snowflake/connectionSettings':
        return <ConnectionSettings />;
      case 'WorkdayBilling/authentication':
        return (
          <WDBAuthentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'WorkdayBilling/execution':
        return <WDBExecution tabName='execution' newLayout={tabDetails['newLayout']} />;
      case 'WorkdayBilling/fieldMapping':
        return <WDBFieldMapping tabName='fieldMapping' />

      // SAP connectors custom tabs      
      case 'SAP/Billing/authentication':
        return (
          <SAPBillingAuthentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'SAP/Billing/destination':
        return <Destination system='Billing' />;
      case 'SAP/Billing/Execution':
        return <SAPBillingExecution />;

      case 'SAP/Revenue/authentication':
        return (
          <SAPRevenueAuthentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'SAP/Revenue/destination':
        return <Destination system='Revenue' />;
      case 'SAP/Revenue/Execution':
        return <SAPRevenueExecution />;

      case 'ZDQ/GcpPostgres/setup':
        return <ZdqGcpPostgresConnector></ZdqGcpPostgresConnector>
      case 'ZDQ/GcpPostgres/status':
        return <ZdqGcpPostgresConnectorStatus></ZdqGcpPostgresConnectorStatus>
      case 'ZDQ/AwsAuroraPostgres/setup':
        return <ZdqAwsAuroraPostgresConnector></ZdqAwsAuroraPostgresConnector>
      case 'ZDQ/AwsAuroraPostgres/status':
        return <ZdqAwsAuroraPostgresConnectorStatus></ZdqAwsAuroraPostgresConnectorStatus>
      case 'ZDQ/AwsRds/setup':
        return <ZdqAwsRdsPostgresConnector></ZdqAwsRdsPostgresConnector>
      case 'ZDQ/AwsRds/status':
        return <ZdqAwsRdsPostgresConnectorStatus></ZdqAwsRdsPostgresConnectorStatus>
      case 'ZDQ/GenericPostgres/setup':
        return <ZdqGenericPostgresConnector></ZdqGenericPostgresConnector>
      case 'ZDQ/GenericPostgres/status':
        return <ZdqGenericPostgresConnectorStatus></ZdqGenericPostgresConnectorStatus>
      case 'ZDQ/AuroraMySql/setup':
        return <ZdqAuroraMySqlConnector></ZdqAuroraMySqlConnector>
      case 'ZDQ/AuroraMySql/status':
        return <ZdqAuroraMySqlConnectorStatus></ZdqAuroraMySqlConnectorStatus>
      case 'ZDQ/GenericMySql/setup':
        return <ZdqGenericMySqlConnector></ZdqGenericMySqlConnector>
      case 'ZDQ/GenericMySql/status':
        return <ZdqGenericMySqlConnectorStatus></ZdqGenericMySqlConnectorStatus>
      case 'ZDQ/AwsRedshift/setup':
        return <ZdqAwsRedshiftConnector></ZdqAwsRedshiftConnector>
      case 'ZDQ/AwsRedshift/status':
        return <ZdqAwsRedshiftConnectorStatus></ZdqAwsRedshiftConnectorStatus>
      case 'ZDQ/BigQuery/setup':
        return <ZdqBigQueryConnector></ZdqBigQueryConnector>
      case 'ZDQ/BigQuery/status':
        return <ZdqBigQueryConnectorStatus></ZdqBigQueryConnectorStatus>
      case 'ZDQ/AwsS3/setup':
        return <ZdqAwsS3Connector></ZdqAwsS3Connector>
      case 'ZDQ/AwsS3/status':
        return <ZdqAwsS3ConnectorStatus></ZdqAwsS3ConnectorStatus>


      // TODO: The below 4 cases are for backward compatibility. To be removed later on since these cases will be covered by 
      // AwsRedshift and AwsS3
      case 'ZDQ/Redshift/setup':
        return <ZdqAwsRedshiftConnector></ZdqAwsRedshiftConnector>
      case 'ZDQ/Redshift/status':
        return <ZdqAwsRedshiftConnectorStatus></ZdqAwsRedshiftConnectorStatus>
      case 'ZDQ/S3/setup':
        return <ZdqAwsS3Connector></ZdqAwsS3Connector>
      case 'ZDQ/S3/status':
        return <ZdqAwsS3ConnectorStatus></ZdqAwsS3ConnectorStatus>

      case 'ZDQ/AzureBlobStorage/setup':
        return <ZdqAzureBlobStorageConnector></ZdqAzureBlobStorageConnector>
      case 'ZDQ/AzureBlobStorage/status':
        return <ZdqAzureBlobStorageConnectorStatus></ZdqAzureBlobStorageConnectorStatus>
      case 'ZDQ/AwsAthena/setup':
        return <ZdqAwsAthenaConnector></ZdqAwsAthenaConnector>
      case 'ZDQ/AwsAthena/status':
        return <ZdqAwsAthenaConnectorStatus></ZdqAwsAthenaConnectorStatus>
      case 'ZDQ/GoogleCloudStorage/setup':
        return <ZdqGoogleCloudStorageConnector></ZdqGoogleCloudStorageConnector>
      case 'ZDQ/GoogleCloudStorage/status':
        return <ZdqGoogleCloudStorageConnectorStatus></ZdqGoogleCloudStorageConnectorStatus>
      case 'ZDQ/Databricks/setup':
        return <ZdqDatabricksConnector></ZdqDatabricksConnector>
      case 'ZDQ/Databricks/status':
        return <ZdqDatabricksConnectorStatus></ZdqDatabricksConnectorStatus>
      case 'ZDQ/SqlServer/setup':
        return <ZdqSqlServerConnector></ZdqSqlServerConnector>
      case 'ZDQ/SqlServer/status':
        return <ZdqSqlServerConnectorStatus></ZdqSqlServerConnectorStatus>
      default:
        return <BasicTemplate showHeader={false} id={tenantId} header={tabDetails['titleTranslations'][locale]['tabName']} fieldGroups={tabDetails['fieldGroups']} lastUpdatedBy={lastUpdatedBy} lastUpdatedDate={lastUpdatedDate} tabName={name}/>;
    }
  }

  return (
    <>{renderSwitch(name)}</>
  );
}

export default CustomTab