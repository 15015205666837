import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Grid, Typography, Card, Button, TextField, Spinner, ToastController } from '@platform-ui/design-system';
import { Action, useStoreContext } from '../../../../Store';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { TEST_CONNECTION, SETTINGS } from '../../../../IntegrationApps/IntegrationAppDetails/action_types';
import { windmillRun } from '../../../../Connect/Connect';

interface TestConnectionProps {
  fieldGroups?: object;
}

export const TestConnection: FC<TestConnectionProps> = ({
  fieldGroups
}: TestConnectionProps) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  
  const [host, setHost] = useState<string>(state.settingsHash?.settings?.["Salesforce Host"] || '');
  const [refreshToken, setRefreshToken] = useState<string>(state.settingsHash?.settings?.["Salesforce Refresh Token"] || null);
  const [orgId, setOrgId] = useState<string | null>(state.settingsHash?.settings?.["Salesforce Organization Id"] || state.settingsHash?.external_id || null);
  const [httpsIncludedError, setHttpsIncludedError] = useState<boolean>(false);
  // Local Testing use below
  // const [redirectUri] = useState<string>(window.location.href);
  const [redirectUri] = useState<string>(() => {
    const baseUrl = window.location.origin + '/services/connect/integration_apps/salesforce_cpq';
    const queryParams = '?tabname=settings';
    return baseUrl + queryParams;
  });
  const [invalidHostError, setInvalidHostError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: '',
    duration: 0,
    loading: false
  });
    
  const clientId = "3MVG90tbpWH0j.nld5Cwb1x3loHabIQrFS3H0PI0XCTpdO2WawfP4TfI4AqG0VI6jQCs5oRXeGG994BE3I3EK";
  const clientSecret = "385D173F2111C91617B3A91FC3EAB8E408A032B8E7994D91C4826D94FA59A94B";
    // NOTE(Michael): The client id and secret are scoped only to Zuora domains for redirect and used only to create an app token
  // NOTE(Michael): https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_refresh_token_flow.htm&type=5https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_refresh_token_flow.htm&type=5
  const authorizationUrl = `https://${host}/services/oauth2/authorize?response_type=code&client_id=${clientId}&client_secret=${clientSecret}&scope=refresh_token+full&state=${host}&redirect_uri=${redirectUri}/crm-con/cpqbutton/public/sfdc/auth/callback`;
    
  const handleDispatch = (type: string, payload: any) => {
    dispatch({ type, payload });
  };
  
  const isValidSalesforceUrl = (url: string) => {
    const salesforcePattern = /^https:\/\/.*\.salesforce\.com$/;
    return salesforcePattern.test(url);
  };
  
  const handleHostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHost = e.target.value;
  
    if (newHost.startsWith("https://")) {
      setHttpsIncludedError(true);
    } else {
      setHttpsIncludedError(false);
    }
  
    setHost(newHost);
    handleDispatch(SETTINGS, { "Salesforce Host": newHost });
  
    const fullUrl = `https://${newHost}`;
    if (!isValidSalesforceUrl(fullUrl)) {
      setInvalidHostError(true);
    } else {
      setInvalidHostError(false);
    }
  };
    
  const isTestConnectionDisabled = !host || invalidHostError || httpsIncludedError || loading;
  
  const testAuthorizationEndpoint = async () => {
    try {
      const response = await windmillRun({
        script_path: 'f/cpq/redirect_status_check',
        params: { url: authorizationUrl },
        async: false,
      });
  
      switch (response.statusCode) {
        case 200:
          setToast({
            show: true,
            msg: 'Authorization URL is valid. Proceeding...',
            severity: 'success',
            duration: 3000,
            loading: false,
          });
          return true;
  
        case 400:
          setToast({
            show: true,
            msg: 'Error: The endpoint is not configured in Salesforce.',
            severity: 'error',
            duration: 5000,
            loading: false,
          });
          return false;
  
        case 420:
          setToast({
            show: true,
            msg: 'Error: Invalid Host. Please check your host URL.',
            severity: 'error',
            duration: 5000,
            loading: false,
          });
          return false;
  
        default:
          setToast({
            show: true,
            msg: `Unexpected status code: ${response.statusCode}`,
            severity: 'error',
            duration: 5000,
            loading: false,
          });
          return false;
      }
    } catch (error) {
      setToast({
        show: true,
        msg: `Failed to validate authorization URL: ${error.message}`,
        severity: 'error',
        duration: 5000,
        loading: false,
      });
      console.error("Error during authorization check:", error);
      return false;
    }
  };
  
  const fetchAccessTokenAndOrgId = async (code: string, host: string) => {
    const tokenUrl = `https://${host}/services/oauth2/token?grant_type=authorization_code&client_id=${clientId}&client_secret=${clientSecret}&code=${code}&redirect_uri=${redirectUri}`;

    try {
      const tokenResponse = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
        
      if (!tokenResponse.ok) {
        throw new Error(`Failed to fetch access token: ${tokenResponse.statusText}`);
      }
  
      const tokenJson = await tokenResponse.json();
      const accessToken = tokenJson.access_token;
      const refreshToken = tokenJson.refresh_token
  
      if (!accessToken) {
        throw new Error("Access token not found");
      }
    
      const orgIdUrl = `https://${host}/services/data/v58.0/query?q=SELECT+Id+FROM+Organization`;
      const orgIdResponse = await fetch(orgIdUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
        
      if (!orgIdResponse.ok) {
        throw new Error(`Failed to fetch Org ID: ${orgIdResponse.statusText}`);
      }
  
      const orgIdJson = await orgIdResponse.json();
      let orgId = orgIdJson.records[0]?.Id;
  
      if (!orgId) {
        throw new Error("Org ID not found");
      }
      // Trimmed ordId, sometimes it comes in more length than needed
      orgId = orgId.substring(0, 15)
  
      return { refreshToken, orgId };
    } catch (error) {
      console.error("Error fetching token or Org ID:", error);
      throw error;
    }
  };
  
  
  const saveSettingsToBackend = async (showSuccessMessage = true) => {
    try {
      const settingsHashKeys = Object.keys(state.settingsHash || {});
  
      for (let i = 0; i < state.tabDetails.length; i++) {
        const tab = state.tabDetails[i];
        const keys = Object.keys(state.tabDetails[i] || {});
        for (let j = 0; j < keys.length; j++) {
          const settingsHashKey = keys[j]?.split('/').pop();
          if (!settingsHashKeys.includes(settingsHashKey)) continue;
  
          const fieldGroups = tab[keys[j]]?.fieldGroups || [];
          for (let k = 0; k < fieldGroups.length; k++) {
            const fields = fieldGroups[k]?.fields || [];
            for (let l = 0; l < fields.length; l++) {
              const field = fields[l] || {};
              if (field.required && (!state.settingsHash[settingsHashKey]?.[field.name])) {
                return alert(`${field.titleTranslations?.en || field.name} is REQUIRED!!!`);
              }
            }
          }
        }
      }
      
      const newState = {
        settings_hash: {
          ...state.settingsHash,
          settings: {
            ...state.settingsHash.settings,
            "Salesforce Refresh Token": refreshToken,
            "Salesforce Host": host,
            "Salesforce Organization Id": orgId,
            tray_migrated_refresh_token: false
          },
          external_id: orgId
        }
      };
  
      const csrf = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
      const options = {
        method: state.createOrUpdate === 'update' ? 'PUT' : 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-Token': csrf || ''
        }),
        body: JSON.stringify(newState)
      };
  
      const response = await window.fetch(`${state.settingsUrl}${window.location.search}`, options);
      const data = await response.json();
  
      if (!response.ok) throw new Error(data.message || 'Failed to save authorization code!');
  
      if (showSuccessMessage) {
        setToast({
          show: true,
          msg: 'Settings saved successfully!',
          severity: 'success',
          duration: 5000,
          loading: false,
        });
      }
    } catch (error) {
      setToast({
        show: true,
        msg: error.message,
        severity: 'error',
        duration: 5000,
        loading: false,
      });
      console.error('Error saving settings:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleTestConnection = async () => {
    if (!host) {
      setToast({
        show: true,
        msg: 'Please enter a valid host URL',
        severity: 'error',
        duration: 5000,
        loading: false,
      });
      return;
    }
    
    setToast({
      show: true,
      msg: 'Validating Salesforce Connection...',
      severity: 'info',
      duration: 5000,
      loading: false,
    });
    
    try {
      setLoading(true);
      const isAuthorized = await testAuthorizationEndpoint();
      if (isAuthorized) {
        setTimeout(() => {
          window.location.href = authorizationUrl;
        }, 1000);
      }

      await saveSettingsToBackend(false);

    } catch (error) {
      setToast({
        show: true,
        msg: 'Error testing connection',
        severity: 'error',
        duration: 5000,
        loading: false,
      });
      console.error('Error testing connection:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const initialHost = state.settingsHash?.settings?.["Salesforce Host"] || null;
    const initialRefreshToken = state.settingsHash?.settings?.["Salesforce Refresh Token"] || null;
    if (initialHost !== null) {
      setHost(initialHost);
    }
    if (initialRefreshToken !== null) {
      setRefreshToken(initialRefreshToken);
    }
  }, [state.settingsHash]);
  
  useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramCode = urlParams.get('code');
  const paramState = urlParams.get('state');

  if (paramCode && state) {
    fetchAccessTokenAndOrgId(paramCode, paramState)
      .then(({ refreshToken, orgId }) => {
        setRefreshToken(refreshToken);
        setOrgId(orgId);
        handleDispatch(SETTINGS, { "Salesforce Refresh Token": refreshToken, "Salesforce Organization Id": orgId, external_id: orgId });

        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('code');
        newUrlParams.delete('state');
        const newUrl = `${window.location.pathname}?${newUrlParams.toString()}`;
        window.history.replaceState({}, document.title, newUrl);
      })
      .catch((error) => {
        console.error("Error during Salesforce OAuth process:", error);
        setToast({
          show: true,
          msg: "Failed to retrieve Org ID",
          severity: "error",
          duration: 5000,
          loading: false,
        });
      });
  }
}, [window.location.search]);

  useEffect(() => {
    if (refreshToken && !window.location.search) {
      const saveTokenToBackend = async () => {
        try {
          setLoading(true);
          await saveSettingsToBackend(false);
    
          setToast({
            show: true,
            msg: 'Refresh Token received and saved successfully!',
            severity: 'success',
            duration: 5000,
            loading: false,
          });
        } catch (error) {
          setToast({
            show: true,
            msg: 'Failed to save Refresh Token to backend.',
            severity: 'error',
            duration: 5000,
            loading: false,
          });
          console.error('Error saving refresh token:', error);
        } finally {
          setLoading(false);
        }
      };
      
      saveTokenToBackend();
    }
  }, [refreshToken]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          id="test-connection-tab"
          header="Salesforce Test Connection"
          titleBar
          body={
            <Grid container direction="column" spacing={2}>
              
              <Grid item xs={12}>
                {state.settingsHash?.settings?.["Salesforce Refresh Token"] ? (
                  <Typography variant="body2">
                    Connection Status: <span style={{ color: 'green' }}>Connected</span>
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Connection Status: <span style={{ color: 'red' }}>Not Connected</span> (No Refresh Token)
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                {state.settingsHash?.external_id && (
                  <Typography variant="body2">
                    Salesforce Org ID: <span style={{ color: 'green' }}>{state.settingsHash?.external_id.substring(0, 15)}</span>
                  </Typography>
                )}
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="h6">
                  Enter the Salesforce Host
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Host URL"
                  value={host}
                  dsOnChange={handleHostChange}
                  placeholder="your-salesforce-domain.salesforce.com (do not include https://)"
                />
              </Grid>
              
              <Grid item xs={12}>
                {invalidHostError && (
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    Invalid Salesforce URL
                  </Typography>
                )}
                {httpsIncludedError && (
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    Please remove "https://" from the URL.
                  </Typography>
                )}
              </Grid>
              
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={isTestConnectionDisabled}
                  dsOnClick={handleTestConnection}
                >
                  {loading ? <Spinner /> : 'Test Connection'}
                </Button>
                {state.modified && (
                  <Typography variant="body2" sx={{ color: 'red', marginLeft: '10px' }}>
                    Please save your changes before testing the connection.
                  </Typography>
                )}
              </Grid>
              
              {toast.show && (
                <ToastController
                  closeable
                  loading={toast.loading}
                  duration={toast.duration}
                  severity={toast.severity as any}
                  message={toast.msg}
                />
              )}
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default TestConnection;
