import { Box } from "@mui/material"
import { Card, Icon, Typography, designTokens } from "@platform-ui/design-system"
import React from "react"

interface MetricsInfo {
    tooltip: string,
    title: string,
    data: string
}
function MetricsInfoCard({ tooltip, title, data }: MetricsInfo) {

    return <Card autoDistance
        space="small" id="metrics-info"
        variant="outlined"
        header={
            <Typography variant="subtitle1" color={designTokens.colors.coolGray500} body={<Box sx={{ display: 'flex' }}>{title} <Icon body="info" tooltip={<Typography variant="body1" body={tooltip} />} /></Box>} />
        }
        body={data &&
            <Typography variant="h4" body={data} truncated tooltipOnlyWhenTruncated tooltip={data} noWrap />
        }>
    </Card>

}

export default MetricsInfoCard