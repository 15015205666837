const initialState = {
  databaseName: '',
  accountId: '',
  userName: '',
  password: '',
  role: '',
  warehouse: '',
  poweredBySnowflakeEnabled: false,
  validating: false,
  saving: false,
  savingSucceeded: true,
  loadedConfigInfo: {
    databaseName: '',
    accountId: '',
    userName: '',
    password: '',
    role: '',
    warehouse: '',
    poweredBySnowflakeEnabled: false,
    loaded: false,
  },
  fieldErrors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDatabaseName':
      return { ...state, databaseName: action.payload };
    case 'setAccountId':
      return { ...state, accountId: action.payload };
    case 'setUserName':
      return { ...state, userName: action.payload };
    case 'setPassword':
      return { ...state, password: action.payload };
    case 'setRole':
      return { ...state, role: action.payload };
    case 'setWarehouse':
      return { ...state, warehouse: action.payload };
    case 'togglePoweredBySnowflake':
      return {
        ...state,
        poweredBySnowflakeEnabled: !state.poweredBySnowflakeEnabled,
      };
    case 'loadConfigInfo':
      return {
        ...state,
        databaseName: action.payload.database,
        accountId: action.payload.accountId,
        userName: action.payload.username,
        password: action.payload.password,
        role: action.payload.role,
        warehouse: action.payload.warehouse,
        poweredBySnowflakeEnabled: action.payload.poweredBySnowflake,
        loadedConfigInfo: {
          databaseName: action.payload.database,
          accountId: action.payload.accountId,
          userName: action.payload.username,
          password: action.payload.password,
          role: action.payload.role,
          warehouse: action.payload.warehouse,
          poweredBySnowflakeEnabled: action.payload.poweredBySnowflake,
          loaded: !!action.payload.database,
        },
      };
    case 'setFieldErrors':
      return {
        ...state,
        fieldErrors: action.payload,
        validating: false,
      };
    case 'clearErrors':
      return {
        ...state,
        fieldErrors: [],
      };
    case 'setValidating':
      return {
        ...state,
        validating: action.payload,
      };
    case 'setSaving':
      return {
        ...state,
        saving: true,
      };
    case 'setSavingSucceeded':
      return {
        ...state,
        saving: false,
        savingSucceeded: true,
      };
    case 'setSavingFailed':
      return {
        ...state,
        saving: false,
        savingSucceeded: false,
      };
    default:
      return state;
  }
};

export { initialState, reducer };