interface AccountInfoState {
  region: String;
  accountName: String;
  accountEdition: String;
  installerFirstName: String;
  installerLastName: String;
  installerEmail: String;
  installDate: Number;
  alreadyInstalled: boolean;
  validating: boolean;
}

const initialState: AccountInfoState = {
  region: '',
  accountName: '',
  accountEdition: '',
  installerFirstName: '',
  installerLastName: '',
  installerEmail: '',
  installDate: 0,
  alreadyInstalled: false,
  validating: false,
};

const reducer = (state: AccountInfoState, action) => {
  switch (action.type) {
    case 'setRegion':
      return { ...state, region: action.payload };
    case 'setAccountName':
      return { ...state, accountName: action.payload };
    case 'setAccountEdition':
      return { ...state, accountEdition: action.payload };
    case 'setInstallerFirstName':
      return { ...state, installerFirstName: action.payload };
    case 'setInstallerLastName':
      return { ...state, installerLastName: action.payload };
    case 'setInstallerEmail':
      return { ...state, installerEmail: action.payload };
    case 'setInstallDate':
      return { ...state, installDate: action.payload };
    case 'setAlreadyInstalled':
      return { ...state, alreadyInstalled: action.payload };
    case 'loadAccountInfo':
      return {
        ...state,
        region: action.payload.region,
        accountName: action.payload.accountName,
        accountEdition: action.payload.accountEdition,
        installerFirstName: action.payload.userFirstName,
        installerLastName: action.payload.userLastName,
        installerEmail: action.payload.userEmail,
        installDate: action.payload.installationDate,
        alreadyInstalled: Boolean(action.payload.installationDate),
      };
    case 'setValidating':
      return {
        ...state,
        validating: action.payload,
      };
    default:
      return state;
  }
};

export { initialState, reducer };