import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Button, Card, Chip, Grid, Modal, ModalActions, Spinner, Typography, designTokens } from '@platform-ui/design-system';
import React, { useEffect, useState } from 'react';
import Connect from '../../../../Connect/Connect';
import { useStoreDispatch } from '../../../../Store';
import { useToast } from '../../../../ToastProvider';
import { BasicAuth } from '../BasicAuth/BasicAuth';
import { OAuth } from '../OAuth/OAuth';
import { ZDP_AUTH_SETUP } from '../action_types';
import { useFlipperHook } from '../../../../Tables/helpers';

export const Authentication = () => {
  const dispatch = useStoreDispatch();
  const [authSelection, setAuthSelection] = useState('');
  const [savedCreds, setSavedCreds] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const appsV1CredsPath = '/apps/v1/getSfdcCredential';
  const selectOauthMessage = 'Are you sure you want to use OAuth Credentials for sync?';
  const selectBasicMessage = 'Are you sure you want to use Salesforce Credentials for sync?';
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalData, setModalData] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { setToast } = useToast();
  const [hideLegacyChip, _1] = useFlipperHook('hide_legacy_ui_chip');

  const fetchCreds = async () => {
    try {
      setLoading(true);
      const response = await Connect.proxyCall(appsV1CredsPath, 'GET', undefined, { 'Accept': 'application/json' });
      if (!response.ok) throw Error(response.statusText);
      const { data } = await response.json();
      setSavedCreds(data || {});
      setAuthSelection(data == null ? '' : data.oauthEnabled ? 'oauth' : 'basic');
      if (data) {
        dispatch({ type: ZDP_AUTH_SETUP, payload: { authSet: true } });
      }
    } catch (err) {
      Connect.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCreds();
  }, []);

  return (
    <>
      <Modal
        id='salesforce-auth-confirm-modal'
        open={openConfirm}
        dsOnClose={() => setOpenConfirm(false)}
        disableBackdropClick
        body={<Typography variant='h5' body={modalData === 'basic' ? selectBasicMessage : selectOauthMessage} />}
        footer={
          <ModalActions>
            <Button variant='outlined' disabled={confirmLoading} dsOnClick={() => setOpenConfirm(false)}>
              No
            </Button>
            <Button
              disabled={confirmLoading}
              dsOnClick={async () => {
                try {
                  setConfirmLoading(true);
                  let response = await fetch('/apps/SfdcSync.do?method=checkIfSyncInProgress');
                  if (!response.ok) throw Error(response.statusText);
                  const { syncInprogress } = await response.json();
                  if (syncInprogress) {
                    setToast({
                      message: 'Object syncing is in progress. Please try again later!',
                      severity: 'warning'
                    });
                  } else {
                    response = await fetch(`/apps/SfdcSync.do?method=enableOAuth&type=${modalData === 'oauth'}`);
                    if (!response.ok) throw Error(response.statusText);
                    setOpenConfirm(false);
                    setAuthSelection(modalData);
                  }
                } catch (err) {
                  setToast({
                    message: err.message || 'Error setting up credentials. Please try again later!',
                    severity: 'error'
                  })
                } finally {
                  setConfirmLoading(false);
                }
              }}
            >
              Yes
            </Button>
          </ModalActions>
        }
      />
      {
        loading ?
        <Spinner /> :
        <Card
          id='authentication-id'
          header={
            <>
              <Grid container alignItems='center'>
                <Grid container item xs direction='column' spacing={0.5}>
                  <Grid container item alignItems='center' spacing={1}>
                    <Grid item><Typography variant='h5'>Salesforce Credentials { !hideLegacyChip && <Chip size='medium' dsOnClick={() => window.location.href = '/apps/SfdcSync.do'} label={<Typography variant='overline'>Back to Legacy UI</Typography>} state='info' />}</Typography></Grid>
                  </Grid>
                  <Grid item><Typography variant='body2'>Set up sync credentials to connect to Salesforce</Typography></Grid>
                </Grid>
              </Grid>
              <Divider style={{marginTop: '20px'}} />
            </>
          }
          body={
            <Grid container>
              <Grid key='auth-selection-key' item xs={12}>
                <>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControl>
                        <FormLabel>Credentials Type</FormLabel>
                        <RadioGroup
                          row
                          defaultValue={authSelection}
                          value={authSelection}
                        >
                          <FormControlLabel
                            value='basic'
                            control={<Radio size='medium' />}
                            label='Salesforce'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setOpenConfirm(true);
                              setModalData(e.target.value);
                            }}
                          />
                          <FormControlLabel
                            value='oauth'
                            control={<Radio size='medium' />}
                            label='OAuth'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setOpenConfirm(true);
                              setModalData(e.target.value);
                            }} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <hr />
                  { authSelection === 'oauth' && <OAuth savedCreds={savedCreds} /> }
                  { authSelection === 'basic' && <BasicAuth savedCreds={savedCreds} /> }
                </>
              </Grid>
            </Grid>
          }
        />
      }
    </>
  )
};
