import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import { TreeView as MUITreeView, TreeItem } from '@mui/x-tree-view';
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

export interface Tree {
  id: string;
  label: string;
  value: string;
  enabled?: boolean;
  children?: Tree[];
}

export interface TreeViewProps {
  root: Tree;
  name?: string;
  setSelectedObjects?: Dispatch<SetStateAction<string[]>>;
  defaultExpanded?: string[];
}

const TreeView: FC<TreeViewProps> = ({ root, name, setSelectedObjects, defaultExpanded }: TreeViewProps) => {
  const [selected, setSelected] = useState([]);
  const selectedSet = useMemo(() => new Set(selected), [selected]);
  const parentMap = useMemo(() => goThroughAllNodes(root), []);

  function goThroughAllNodes(nodes: Tree, map: Record<number, any> = {}) {
    if (nodes.children) {
      map[nodes.id] = getAllChildren(nodes).filter((node) => node.enabled).map((node) => node.id).splice(1);
      for (const childNode of nodes.children) {
        goThroughAllNodes(childNode, map);
      }
    }
    return map;
  };

  function getAllChildren(childNode: Tree | null, collectedNodes: any[] = []) {
    if (childNode) {
      collectedNodes.push(childNode);
      if (Array.isArray(childNode.children)) {
        for (const node of childNode.children) {
          getAllChildren(node, collectedNodes);
        }
      }
    }
    return collectedNodes;
  };

  const getChildById = (nodes: Tree, id: string) => {
    let array: string[] = [];
    let path: string[] = [];

    function getNodeById(node: Tree, id: string, parentsPath: string[]) {
      let result = null;
      if (node.id === id) {
        return node;
      } else if (Array.isArray(node.children)) {
        for (let childNode of node.children) {
          result = getNodeById(childNode, id, parentsPath);
          if (!!result) {
            parentsPath.push(node.id);
            return result;
          }
        }
        return result;
      }
      return result;
    }

    const nodeToToggle = getNodeById(nodes, id, path);

    return {
      childrenNodesToToggle: getAllChildren(nodeToToggle, array).filter((node) => node.enabled).map((node) => node.id),
      path
    };
  };

  const getOnChange = (checked: boolean, nodes: Tree) => {
    if (nodes.id !== '-1') {
      const array = checked ? [...selected, nodes.id] : selected.filter((value) => value !== nodes.id);
      setSelected(array);
      setSelectedObjects(array);
      return;
    }

    const { childrenNodesToToggle, path } = getChildById(root, nodes.id);
    
    let array = checked 
      ? [...selected, ...childrenNodesToToggle]
      : selected
          .filter((value) => !childrenNodesToToggle.includes(value))
          .filter((value) => !path.includes(value));

    array = array.filter((value, index) => array.indexOf(value) === index).filter((value) => value !== '-1');

    setSelected(array);
    setSelectedObjects(array);
  };

  const renderTree = (nodes: Tree) => {
    const allSelectedChildren = parentMap[nodes.id]?.every((childNodeId: number) => selectedSet.has(childNodeId));
    const checked = nodes.enabled ? (nodes.id === '-1' ? selectedSet.has(nodes.id) || allSelectedChildren || false : selectedSet.has(nodes.id)) : false;

    const indeterminate = parentMap[nodes.id]?.some((childNodeId: number) => selectedSet.has(childNodeId)) || false;

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                indeterminate={nodes.id === '-1' ? !allSelectedChildren && indeterminate : false} // only show indeterminate for node id '-1' - SelectAll
                onChange={(event) => getOnChange(event.currentTarget.checked, nodes)}
                onClick={(event) => event.stopPropagation()}
                disabled={!nodes.enabled}
              />
            }
            label={<>{nodes.label}</>}
            key={`${nodes.label}-${nodes.id}`}
            sx={{ paddingTop: '3px' }}
          />
        }
      >
        {
          Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null
        }
      </TreeItem>
    );
  };

  return (
    <MUITreeView
      id={`${name}-id`}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      defaultExpanded={defaultExpanded || ['-1']}
    >
      {renderTree(root)}
    </MUITreeView>
  );
};

export default TreeView;