import { Box, Button, Checkbox, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Alert, Card, Chip, Grid, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, useEffect, useState } from 'react';
import Connect from "../../../../Connect/Connect";
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { useFlipperHook } from '../../../../Tables/helpers';
import { useToast } from '../../../../ToastProvider';
import ManualSync from './ManualSync';
import { useIdentity } from '../../../../IdentityProvider';

declare global {
  interface Window {
    connect: any;
  }
}

interface enabledAccountObject {
  object: string;
  enabled: boolean;
}

interface productCatalogEnablementData {
  group: string;
  enabled: boolean;
  objects: string[];
}

const LoadingButton = ({
  text = 'Save',
  disabled,
  onClick,
  loading
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        variant='contained'
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </Button>
      {
        loading &&
        <CircularProgress
          size={24}
          sx={{
            color: designTokens.colors.blue500,
            position: 'absolute',
            top: '50%',
            left: '33px',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      }
    </Box>
  );
};

function SyncSettings() {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const tabName = 'syncSettings';
  const accountObjectIndents = new Map<string, number>([
    ['Account', 2],
    ['Contact', 4],
    ['Product', 4],
    ['UnitOfMeasure', 4],
    ['PaymentGateway', 4],
    ['PaymentTerm', 4],
    ['Invoice', 4],
    ['InvoiceItem', 6],
    ['TaxationItem', 8],
    ['PaymentMethod', 4],
    ['Payment', 6],
    ['InvoicePayment', 8],
    ['PaymentPart', 8],
    ['Subscription', 4],
    ['RatePlan', 6],
    ['RatePlanCharge', 8],
    ['SubscriptionHistory', 4],
    ['RatePlanHistory', 6],
    ['RatePlanChargeHistory', 8],
    ['RatePlanChargeTierHistory', 10],
    ['Refund', 4],
    ['RefundPart', 6],
    ['RefundInvoicePayment', 6],
    ['CreditMemo', 4],
    ['CreditMemoItem', 6],
    ['CreditMemoPart', 6],
    ['DebitMemo', 4],
    ['DebitMemoItem', 6],
    ['PaymentSchedule', 4],
    ['PaymentScheduleItem', 6],
  ]);

  const allAccountObjects = Array.from(accountObjectIndents.keys());

  const allProductCatalogObjects = [
    'ProductCatalog',
    'BillingEntity',
    'Feature',
    'Product2',
    'ProductFeature',
    'ProductRatePlan',
    'ProductRatePlanCharge',
    'ProductRatePlanChargePriceSummary',
    'ProductRatePlanChargeTier',
    'ZProduct',
    'ZUnitOfMeasure',
  ];

  const syncSubheader = 'Use this page to push Zuora information into salesforce, or to clean up Zuora information from your existing Salesforce data.';

  const enablementSubheader = (<div>This page allows you to control what objects are synced through the Zuora connector for Salesforce CRM.<br/>
  For more information, visit the <a href='https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connector_for_Salesforce_CRM'>Zuora Connector for Salesforce CRM knowledge Center</a>.<br/>
  Please allow 15 minutes after saving any changes before verifying that your selected objects are syncing.
  </div>);

  const [manualSyncEnabled, _] = useFlipperHook('ih_enable_manual_sync');
  const [hideLegacyChip, _1] = useFlipperHook('hide_legacy_ui_chip');
  const [openManualSync, setOpenManualSync] = useState(false);
  const { setToast } = useToast();
  const [savingAllObjects, setSavingAllObjects] = useState(false);
  const { hasPermission } = useIdentity();

  const savingAllObjectsHandler = async () => {
    try {
      setSavingAllObjects(true);

      const enabledAccountObjects = [];
      Object.keys(state.settingsHash[tabName] || {}).forEach(key => {
        if (allAccountObjects.includes(key)) {
          enabledAccountObjects.push({
            object: key,
            enabled: state.settingsHash[tabName][key]
          });
        }
      });

      let response = await Connect.proxyCall(
        `${window.location.host.includes('local') ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2/datacon/tenant-metadata`,
        'PUT',
        enabledAccountObjects,
        {
          'Content-Type': 'application/json',
          'Zuora-Tenant-Id': window.connect?.tenant?.tenant_id
        }
      );
      
      if (!response.ok) {
        const { message } = await response.json();
        throw Error(message);
      }

      response = await Connect.proxyCall(
        `${window.location.host.includes('local') ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2/datacon/tenant-metadata/groups/ProductCatalog`,
        'PUT',
        {
          enabled: !!((state.settingsHash[tabName] || {})['ProductCatalog'])
        },
        {
          'Content-Type': 'application/json'
        }
      );

      if (!response.ok) {
        const { message } = await response.json();
        throw Error(message);
      }

      setToast({
        message: 'Objects Enablement Updated.',
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setToast({
        message: err.message || 'Error enabling objects',
        severity: 'error'
      });
    } finally {
      setSavingAllObjects(false);
    }
  };

  const handleDispatch = (type: string, payload: any) => {
    dispatch({ type, payload });
  };

  const handleClickObject = (object: string) => {
    const updatedSettings = {
      ...(state.settingsHash[tabName] || {}),
      [object]: !enabled(object)
    };
    handleDispatch(tabName, updatedSettings);
  };

  function updateProductCatalogObjectsEnablementStatuses(enabled: boolean) : void {
    // Note(Duc): We do not want to show unsaved changes if there's a mismatch between what we store and what daco returns. We just need to merge these two lists.
    const updatedSettings = { ...state.settingsHash[tabName], merge: true };

    allProductCatalogObjects.forEach((object: string) => {
      updatedSettings[object] = enabled;
    });

    handleDispatch(tabName, updatedSettings);
  }

  function updateAccountObjectsEnablementStatuses() : void {
    const updatedSettings = { ...state.settingsHash[tabName] };

    const enabled : boolean = !allAccountObjectsAreEnabled();
    allAccountObjects.forEach((object: string) => {
      updatedSettings[object] = enabled;
    });

    handleDispatch(tabName, updatedSettings);
  }

  function enabled(item: string) : boolean {
    return !!state.settingsHash[tabName]?.[item];
  }

  function allAccountObjectsAreEnabled() : boolean {
    for (const accountObject of allAccountObjects) {
      if (!enabled(accountObject)) {
        return false;
      }
    }
    return true;
  }

  function noAccountObjectsAreEnabled() : boolean {
    for (const accountObject of allAccountObjects) {
      if (enabled(accountObject)) {
        return false;
      }
    }
    return true;
  }

  function addSpaceToCamelCase(input: string) : string {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  function waitFor(variable, callback) {
    var interval = setInterval(function() {
      if (window[variable]) {
        clearInterval(interval);
        callback();
      }
    }, 200);
  }

  async function fetchAccountObjectsEnablementData(headers: any) : Promise<enabledAccountObject[]> {
    try {
      const url = `${window.location.host.includes('local') ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2/datacon/tenant-metadata/metadata-ui-only`;
      const response : Response = await Connect.proxyCall(url, 'GET', null, headers);
      if (!response) throw new Error('No response received');
      if (!response.ok) throw Error(response['message'] || `Unsuccessful response received`);
      return await response.json();
    } catch (error) {
      Connect.log(error);
      // return an empty array to error out silently instead of blocking the execution
      return []
    }
  }

  async function fetchProductCatalogObjectsEnablementData(headers: any) : Promise<productCatalogEnablementData> {
    try {
      const url = `${window.location.host.includes('local') ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2/datacon/tenant-metadata/product-catalog-group-from-ui`;
      const response : Response = await Connect.proxyCall(url, 'GET', null, headers);
      if (!response) throw new Error('No response received');
      if (!response.ok) throw Error(response['message'] || `Unsuccessful response received`);
      return await response.json();
    } catch (error) {
      Connect.log(error);
      // return a default object to error out silently instead of blocking the execution
      return { group: '', enabled: false, objects: [] };
    }
  }

  async function updateObjectEnablementDataAccount(accountData: enabledAccountObject[]) {
    // Expected response format: [{"object":"Account","enabled":true},{"object":"Contact","enabled":true}]
    try {
      const newSettings = {};
      accountData.forEach(item => {
        if (enabled(item.object) !== item.enabled) {
          newSettings[item.object] = item.enabled;
        }
      });
      if (Object.keys(newSettings).length > 0) {
        // Note(Duc): We do not want to show unsaved changes if there's a mismatch between what we store and what daco returns. We just need to merge these two lists.
        newSettings['merge'] = true;
        handleDispatch(tabName, newSettings);
      }
    } catch (error) {
      // log the error and error out silently
      Connect.log(error);
    }
  }

  async function updateObjectEnablementDataProductCatalog(productCatalogData: productCatalogEnablementData) {
    // Expected response format: {
    //     "group": "ProductCatalog",
    //     "enabled": false,
    //     "objects": [
    //         "BillingEntity",
    //         "Feature",
    //         "Product2",
    //         "ProductFeature", // Note(bkues): this object is left out for some unknown reason
    //         "ProductRatePlan",
    //         "ProductRatePlanCharge",
    //         "ProductRatePlanChargePriceSummary",
    //         "ProductRatePlanChargeTier",
    //         "ZProduct",
    //         "ZUnitOfMeasure"
    //     ]
    // }
    try {
      if (enabled('ProductCatalog') !== productCatalogData.enabled) {
        updateProductCatalogObjectsEnablementStatuses(productCatalogData.enabled);
      }
    } catch (error) {
      // log the error and error out silently
      Connect.log(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      while (!window.hasOwnProperty('connect')) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      waitFor('connect', async function() {
        const headers = {
          'content-type': 'application/json',
          'tenant-id':  window.connect.tenant.tenant_id
        };
        fetchAccountObjectsEnablementData(headers)
          .then((accountData : enabledAccountObject[]) =>
            updateObjectEnablementDataAccount(accountData));

        fetchProductCatalogObjectsEnablementData(headers)
          .then((productCatalogData : productCatalogEnablementData) =>
            updateObjectEnablementDataProductCatalog(productCatalogData));
      });
    };

    fetchData();
  }, []);

  if (!state.credsModified) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must set up credentials in Authentication tab first' open={true} />
    );
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Card
          id='zdp-sync-settings-top-ui'
          body={
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid container item xs direction='column' spacing={0.5}>
                    <Grid container item alignItems='center' spacing={1}>
                      <Grid item><Typography variant='h5'>Manual Sync</Typography></Grid>
                    </Grid>
                    <Grid item><Typography variant='body2'>{syncSubheader}</Typography></Grid>
                  </Grid>
                  <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
                    <ManualSync openManualSync={openManualSync} setOpenManualSync={setOpenManualSync} tabName={tabName} />
                    { manualSyncEnabled && <Button variant='contained' onClick={() => setOpenManualSync(true)}>Sync</Button> }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>

      <Grid item>
        <Card
          id='zdp-sync-settings-top-ui'
          body={
            <Grid container>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid container item xs direction='column' spacing={0.5}>
                    <Grid container item alignItems='center' spacing={1}>
                      <Grid item><Typography variant='h5'>Object Enablement { !hideLegacyChip && <Chip size='medium' dsOnClick={() => window.location.href = '/platform/apps/data-connect/object-enablement'} label={<Typography variant='overline'>Go Back to old UI</Typography>} state='info'/> } </Typography> </Grid>
                    </Grid>
                    <Grid item><Typography variant='body2'>{enablementSubheader}</Typography></Grid>
                  </Grid>
                  <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
                    <LoadingButton
                      disabled={savingAllObjects || !state.settingsHash[tabName]}
                      onClick={savingAllObjectsHandler}
                      loading={savingAllObjects}
                    />
                  </Grid>
                </Grid>
                <hr />
              </Grid>
              <Grid item xs={6}>
                <List
                  sx={{ width: '100%', maxWidth: 720, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton sx={{ pl: 0}} onClick={() => updateAccountObjectsEnablementStatuses()}>
                    <ListItemIcon>
                      <Checkbox checked={allAccountObjectsAreEnabled()} indeterminate={!noAccountObjectsAreEnabled() && !allAccountObjectsAreEnabled()} />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </ListItemButton>
                  <Divider />
                  <List component="div" disablePadding>
                    {
                      allAccountObjects.map((object) => {
                        let disabled = false;
                        let tooltip: React.ReactNode = '';
                        if (['CreditMemo', 'CreditMemoItem', 'CreditMemoPart', 'DebitMemo', 'DebitMemoItem'].includes(object)) {
                          disabled = !hasPermission('permission.ARSettlement') && !hasPermission('permission.InvoiceSettlementHarmonization');
                          tooltip = disabled ? <Typography variant='titleS'>ARSettlement and InvoiceSettlementHarmonization are turned off</Typography> : '';
                        }
                        return (
                          <Tooltip title={tooltip} arrow>
                            <div>
                            <ListItemButton disabled={disabled} sx={{ pl: accountObjectIndents.get(object) }} onClick={() => handleClickObject(object)}>
                              <ListItemIcon>
                                <Checkbox checked={enabled(object)} />
                              </ListItemIcon>
                              <ListItemText primary={addSpaceToCamelCase(object)} />
                            </ListItemButton>
                            </div>
                          </Tooltip>
                        );
                      })
                    }
                  </List>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List
                  sx={{ width: '100%', maxWidth: 720, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton onClick={() => updateProductCatalogObjectsEnablementStatuses(!enabled('ProductCatalog'))}>
                    <ListItemIcon>
                      <Checkbox checked={enabled('ProductCatalog')} />
                    </ListItemIcon>
                    <ListItemText primary="Product Catalog" />
                  </ListItemButton>
                  <Divider />
                  <List component="div" disablePadding>
                    {
                      allProductCatalogObjects.filter(object => object != 'ProductCatalog').map((object) => (
                        <ListItemButton sx={{ pl: 4 }} disabled={true}>
                          <ListItemIcon>
                            <Checkbox checked={enabled(object)} />
                          </ListItemIcon>
                          <ListItemText primary={addSpaceToCamelCase(object)} />
                        </ListItemButton>
                      ))
                    }
                  </List>
                </List>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}

export default SyncSettings;
