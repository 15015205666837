import React, { useEffect, useReducer, useState } from 'react';
import {
  ButtonSectionStyle,
  SnowflakeInputRowSectionStyle,
  SnowflakeInputSectionStyle,
} from '../../styles';
import {
  Button,
  Card,
  Checkbox,
  TextField,
  Typography,
  Modal,
  Spinner,
  Tooltip,
  Icon,
  designTokens,
  Link,
} from '@platform-ui/design-system';
import {
  initialState as configInitialState,
  reducer as configReducer,
} from '../../stores/ConfigInfo';
import { Box } from '@mui/material';
import { useToast } from '../../../../../ToastProvider';
import Connect from '../../../../../Connect/Connect';

const ConfigInfo = () => {
  const { setToast } = useToast();
  const [configFormEditable, setConfigFormEditable] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [configFormState, configFormDispatch] = useReducer(
    configReducer,
    configInitialState,
  );
  const isLocal = window.location.host.includes('localhost');
  const proxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  const hasErrors = (fieldName) => {
    const { fieldErrors } = configFormState;
    return fieldErrors.includes(fieldName);
  };

  const clearConfigErrors = () => {
    configFormDispatch({ type: 'clearErrors' });
  };

  const fetchConfigInfo = async () => {
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/snowflakeconfig`,
        'GET',
        undefined,
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      );
      configFormDispatch({ type: 'loadConfigInfo', payload: await response.json() });
    } catch (error) {
      Connect.log(`Error fetching snowflake config info: ${error}`);
    }
  };

  const upsertConfigInfo = () => {
    configFormDispatch({ type: 'setSaving' });
    const {
      userName: username,
      password,
      role,
      warehouse,
      accountId,
      databaseName: database,
    } = configFormState;

    const body = {
      username,
      password,
      role,
      warehouse,
      accountId,
      database,
      poweredBySnowflake: configFormState.poweredBySnowflakeEnabled,
    };

    let response;
    if (configFormState.loadedConfigInfo.loaded) {
      response = Connect.proxyCall(
        `${proxyPath}/shares/snowflakeconfig`,
        'PUT',
        body,
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      );
    } else {
      response = Connect.proxyCall(
        `${proxyPath}/shares/snowflakeconfig`,
        'POST',
        body,
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      );
    }

    response.then(() => {
      configFormDispatch({ type: 'setSavingSucceeded' });
      setToast({
        message: 'The Snowflake configuration was successfully saved.',
        severity: 'success',
        keyRender: Date.now(),
      });
      setConfigFormEditable(!configFormEditable);

      //fetch config info to override the loaded values
      fetchConfigInfo();
    }).catch(() => {
      configFormDispatch({ type: 'setSavingFailed' });
      setToast({
        message: 'There was a problem saving the Snowflake configuration.',
        severity: 'error',
        keyRender: Date.now(),
      });
    });
  };

  const validateConfigInfo = async () => {
    configFormDispatch({ type: 'setValidating', payload: true });
    const {
      userName: username,
      password,
      role,
      warehouse,
      accountId,
      databaseName: database,
    } = configFormState;

    Connect.proxyCall(
      `${proxyPath}/shares/snowflakeconfig/validate`,
      'POST',
      {
        username,
        password,
        role,
        warehouse,
        accountId,
        database,
      },
      { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    )
    .then((response) => response.json())
    .then((data) => {
      if (!data.valid) {
        setToast({
          message: 'There were errors validating the supplied configuration.',
          severity: 'error',
          keyRender: Date.now(),
        });
        configFormDispatch({
          type: 'setFieldErrors',
          payload: data.reasons,
        });
      } else {
        configFormDispatch({ type: 'setValidating', payload: false });
        configFormDispatch({ type: 'togglePoweredBySnowflake' });
      }
    }).catch(() => {
      setToast({
        message: 'We encountered an error validating the supplied configuration.',
        severity: 'error',
        keyRender: Date.now(),
      });
    });
  };

  useEffect(() => {
    fetchConfigInfo();
  }, []);

  const refreshConfigInfoForm = () => {
    setConfigFormEditable(!configFormEditable);
    fetchConfigInfo();
    clearConfigErrors();
  };

  const configInfoHasChanges = () => {
    const {
      databaseName,
      accountId,
      userName,
      password,
      role,
      warehouse,
      poweredBySnowflakeEnabled,
    } = configFormState;
    const {
      databaseName: loadedDatabaseName,
      accountId: loadedAccountId,
      userName: loadedUserName,
      password: loadedPassword,
      role: loadedRole,
      warehouse: loadedWarehouse,
      poweredBySnowflakeEnabled: loadedPoweredBySnowflakeEnabled,
    } = configFormState.loadedConfigInfo;

    if (
      databaseName !== loadedDatabaseName ||
      accountId !== loadedAccountId ||
      userName !== loadedUserName ||
      password !== loadedPassword ||
      role !== loadedRole ||
      warehouse !== loadedWarehouse ||
      poweredBySnowflakeEnabled !== loadedPoweredBySnowflakeEnabled
    ) {
      return true;
    }
    return false;
  };

  const configInfoHasEmptyFields = () => {
    const { databaseName, accountId, userName, password, role, warehouse } =
      configFormState;

    return (
      !databaseName ||
      !accountId ||
      !userName ||
      !password ||
      !role ||
      !warehouse
    );
  };

  const buttonSection = configFormEditable ? (
    <ButtonSectionStyle>
      <Button
        disabled={!configInfoHasChanges() || configInfoHasEmptyFields()}
        dsOnClick={() => {
          upsertConfigInfo();
        }}
      >
        Save
      </Button>
      <Box
        sx={{
          marginLeft: '10px',
        }}
      >
        <Button
          dsOnClick={() => {
            if (!configInfoHasEmptyFields() && configInfoHasChanges()) {
              setShowConfirmCancelModal(true);
            } else {
              refreshConfigInfoForm();
            }
          }}
        >
          Cancel
        </Button>
      </Box>
    </ButtonSectionStyle>
  ) : (
    <></>
  );

  const configFormEditButton = !configFormEditable ? (
    <Button
      variant="outlined"
      dsOnClick={() => {
        setConfigFormEditable(!configFormEditable);
      }}
    >
      Edit
    </Button>
  ) : (
    <></>
  );

  const getConfirmCancelModalContent = () => {
    return (
      <Box sx={{ margin: '20px' }}>
        Are you sure you want to cancel? Your updates will be lost.
        <ButtonSectionStyle>
          <Button
            variant="text"
            dsOnClick={() => {
              setShowConfirmCancelModal(false);
            }}
          >
            Continue Editing
          </Button>
          <Box
            sx={{
              marginLeft: '10px',
            }}
          >
            <Button
              dsOnClick={() => {
                refreshConfigInfoForm();
                setShowConfirmCancelModal(false);
              }}
            >
              Confirm Cancel
            </Button>
          </Box>
        </ButtonSectionStyle>
      </Box>
    );
  };

  const getModalContent = (message) => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingBottom: '10px',
        }}
      >
        <Box sx={{ marginBottom: '5px' }}>{message}</Box>
        <Spinner variant="linear" />
      </Box>
    );
  };

  const content = (
    <>
      <div>
        <Typography>
          This option gives Zuora applications (Zuora Reporting, Zuora Data Query, AQuA, Data Source Exports, and Finance Trial Balance reports) 
          the option to use Snowflake Compute resources from your Snowflake account
          (Snowflake fees apply) to run queries and reports faster and at higher scale.
        </Typography>
      </div>
      <SnowflakeInputRowSectionStyle>
        <div>
          <Box
            sx={{
              display: 'flex',
              marginRight: '20px',
              alignItems: 'center',
            }}
          >
            <TextField
              required
              error={hasErrors('database')}
              disabled={!configFormEditable}
              type="text"
              label="Snowflake Database Name"
              dsOnChange={({ target }) => {
                configFormDispatch({
                  type: 'setDatabaseName',
                  payload: target.value,
                });
              }}
              value={configFormState.databaseName}
            />
            <Box
              sx={{
                alignSelf: 'end',
                cursor: 'default',
                display: 'flex',
                paddingBottom: '8px',
                paddingLeft: '10px',
              }}
            >
              <Tooltip
                dsOnClose={() => {}}
                dsOnOpen={() => {}}
                e2e=""
                title={<Typography body="The Snowflake Database Name is the database built from the incoming Zuora Share. It can only be set after accepting the share from Zuora." />}
              >
                <>
                  <Icon variant="outlined" body="info" fontSize="small" />
                </>
              </Tooltip>
            </Box>
          </Box>
        </div>
      </SnowflakeInputRowSectionStyle>
      <div>
        <SnowflakeInputRowSectionStyle>
          <SnowflakeInputSectionStyle>
            <TextField
              required
              error={hasErrors('accountId')}
              disabled={!configFormEditable}
              type="text"
              label="Snowflake Account Id"
              dsOnChange={({ target }) => {
                configFormDispatch({
                  type: 'setAccountId',
                  payload: target.value,
                });
              }}
              value={configFormState.accountId}
            />
          </SnowflakeInputSectionStyle>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Link
              href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#format-1-preferred-account-name-in-your-organization"
              target="_blank"
              underline="none"
              color={designTokens.colors.navy600}
            >
              <Typography variant="caption">preferred format</Typography>
            </Link>
          </Box>
        </SnowflakeInputRowSectionStyle>
      </div>
      <div>
        <SnowflakeInputRowSectionStyle>
          <SnowflakeInputSectionStyle>
            <TextField
              required
              error={hasErrors('creds')}
              disabled={!configFormEditable}
              type="text"
              label="Snowflake User Name"
              dsOnChange={({ target }) => {
                configFormDispatch({
                  type: 'setUserName',
                  payload: target.value,
                });
              }}
              value={configFormState.userName}
            />
          </SnowflakeInputSectionStyle>
          <SnowflakeInputSectionStyle>
            <TextField
              required
              error={hasErrors('creds')}
              disabled={!configFormEditable}
              type="password"
              label="Snowflake User Password"
              dsOnChange={({ target }) => {
                configFormDispatch({
                  type: 'setPassword',
                  payload: target.value,
                });
              }}
              value={configFormState.password}
            />
          </SnowflakeInputSectionStyle>
        </SnowflakeInputRowSectionStyle>
        <SnowflakeInputRowSectionStyle>
          <SnowflakeInputSectionStyle>
            <TextField
              required
              error={hasErrors('userRole')}
              disabled={!configFormEditable}
              type="text"
              label="Snowflake User Role"
              dsOnChange={({ target }) => {
                configFormDispatch({
                  type: 'setRole',
                  payload: target.value,
                });
              }}
              value={configFormState.role}
            />
          </SnowflakeInputSectionStyle>
          <SnowflakeInputSectionStyle>
            <TextField
              required
              error={hasErrors('warehouse')}
              disabled={!configFormEditable}
              type="text"
              label="Snowflake Warehouse"
              dsOnChange={({ target }) => {
                configFormDispatch({
                  type: 'setWarehouse',
                  payload: target.value,
                });
              }}
              value={configFormState.warehouse}
            />
          </SnowflakeInputSectionStyle>
        </SnowflakeInputRowSectionStyle>
        <SnowflakeInputRowSectionStyle>
          <Checkbox
            checked={configFormState.poweredBySnowflakeEnabled}
            disabled={!configFormEditable || configInfoHasEmptyFields()}
            label="Enable Powered by Snowflake"
            dsOnChange={() => {
              if (!configFormState.poweredBySnowflakeEnabled) {
                validateConfigInfo();
              } else {
                configFormDispatch({ type: 'togglePoweredBySnowflake' });
              }
            }}
          />
        </SnowflakeInputRowSectionStyle>
      </div>
      {buttonSection}
    </>
  );

  return (
    <>
      <Card
        id="configurationSection"
        body={content}
        header="Configure Zuora Applications (Beta)"
        headerAction={configFormEditButton}
        variant='outlined'
      />

      <Modal
        open={configFormState.validating}
        id="configInfoValidationModal"
        dsOnClose={() => {}}
        body={getModalContent('Validating the Snowflake configuration.')}
      />
      <Modal
        open={configFormState.saving}
        id="configInfoSavingModal"
        dsOnClose={() => {}}
        body={getModalContent('Saving the Snowflake configuration.')}
      />
      <Modal
        open={showConfirmCancelModal}
        id="confirmCancelModal"
        dsOnClose={() => {}}
        body={getConfirmCancelModalContent()}
      />
    </>
  );
};

export default ConfigInfo;
