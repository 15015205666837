import { ExistingDestination } from "@prequel/react";
import Connect from "../../../../Connect/Connect";

const fetchAuthTokenWithExistingDestination: (url: string, d?: ExistingDestination) => Promise<string> = async (url, existingDestination) => {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
            },
            body: JSON.stringify(existingDestination),
        });
        const body = await response.json();
        return body.scoped_token;
    } catch (e) {
        Connect.log(e);
        console.error(e);
    }
}

export default fetchAuthTokenWithExistingDestination;