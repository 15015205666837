import { AlertColor, FormControl, FormControlLabel, FormLabel, Switch } from '@mui/material';
import { Button, Grid, Spinner, ToastController } from '@platform-ui/design-system';
import React, { useEffect, useState } from 'react';
import Connect from '../../../../Connect/Connect';
import { ZDP_AUTH_SETUP } from '../action_types';
import { useStoreDispatch } from '../../../../Store';

export const OAuth = ({
  contentDisabled = false,
  savedCreds = { oauthEnabled: false, sandbox: false }
}) => {
  const dispatch = useStoreDispatch();
  const [useSandbox, setUseSandbox] = useState(savedCreds.sandbox);
  const [enableSetup, setEnableSetup] = useState(!savedCreds.oauthEnabled);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });
  const connect: Connect = (window as any).connect;
  const defaultIHPath = `/services/connect/integration_apps/${(connect.tenant as any).connect_id}/integration_apps`;
  const href = `/apps/SfdcSync.do?method=oauthSetup&sandbox=${useSandbox}&state=${window.location.pathname || defaultIHPath}`;

  const deleteOAuthCreds = async () => {
    try {
      setLoading(true);
      setToast({ ...toast, show: false });

      let response = await fetch('/apps/SfdcSync.do?method=checkIfSyncInProgress');
      if (!response.ok) throw Error(response.statusText);
      const { syncInprogress } = await response.json();

      if (syncInprogress) {
        setLoading(false);
        setToast({
          show: true,
          msg: 'Object syncing is in progress. Please try again later!',
          severity: 'warning'
        });
      } else {
        response = await fetch('/apps/SfdcSync.do?method=deleteOauth');
        if (!response.ok) throw Error(response.statusText);
        setLoading(false);
        dispatch({ type: ZDP_AUTH_SETUP, payload: { authSet: false } });
        setToast({
          show: true,
          msg: 'OAuth credentials were deleted successfully.',
          severity: 'success'
        });
        setEnableSetup(true);
      }
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: 'Failed to delete OAuth credentials. Please try again later!',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    if (window.location.search) {
      setToast({ ...toast, show: false });
      const params = new URLSearchParams(window.location.search);
      const oauthMessage = params.get('oauthMessage');
      if (oauthMessage) {
        setToast({
          show: true,
          msg: oauthMessage,
          severity: 'success'
        });
      } else {
        setToast({
          show: true,
          msg: oauthMessage,
          severity: 'error'
        });
      }
    }
  }, [window.location.search]);

  return (
    <>
      {
        loading ?
        <Spinner /> :
        <Grid container direction='column'>
          { toast.show && <ToastController severity={toast.severity} message={toast.msg} /> }
          <Grid key='zdp-oauth-buttons' item xs={6}>
            <Grid container item xs='auto' alignContent='flex-start'>
              <Grid key='zdp-oauth-setup-button' item>
                <Button
                  disabled={!enableSetup}
                  href={href}
                >
                  Set Up
                </Button>
              </Grid>
              <Grid key='zdp-oauth-delete-button' item>
                <Button
                  disabled={enableSetup}
                  dsOnClick={() => deleteOAuthCreds()}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid key='zdp-oauth-use-sandbox-switch' item xs={6}>
            <FormControl component='fieldset' variant='standard' disabled={contentDisabled}>
              <FormLabel>Use Sandbox</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={useSandbox}
                    onChange={(_, checked) => setUseSandbox(checked)}
                    disabled={contentDisabled} />
                }
                label=''
              />
            </FormControl>
          </Grid>
        </Grid>
      }
    </>
  );
};