import React, { useEffect, useReducer, useState } from 'react';
import {
  ButtonSectionStyle,
  ContentPaddingStyle,
  TopMessageInProgress,
  TopMessageCompleted
} from '../../styles';
import {
  Alert,
  Button,
  Card,
  Modal,
  ModalActions,
  ModalTitle,
  Select,
  SelectItem,
  Spinner,
  TextField,
  Typography,
  designTokens
} from '@platform-ui/design-system';
import {
  initialState as accountInfoInitialState,
  reducer as accountInfoReducer
} from '../../stores/AccountInfo';
import { Box } from '@mui/material';
import { useToast } from '../../../../../ToastProvider';
import { useIdentity } from '../../../../../IdentityProvider';
import Connect from '../../../../../Connect/Connect';

const getDatetime = (datetime: string | Number, locale: string, timezone: string) => {
  return Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: timezone,
    timeZoneName: 'short'
  }).format(new Date(Number(datetime)));
};

const AccountInfo = () => {
  const [regions, setRegions] = useState([]);
  const { setToast } = useToast();
  const [provisionStatusMessage, setProvisionStatusMessage] = useState('');
  const [provisionCompletionStatus, setProvisionCompletionStatus] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [accountInfoFormState, accountInfoFormDispatch] = useReducer(
    accountInfoReducer,
    accountInfoInitialState
  );
  const { identity, zdsLocale, timezone } = useIdentity();
  const [loadingProvisionStatus, setLoadingProvisionStatus] = useState(true);
  const showSuccessAlertKey = 'ih_snowflake_show_success_alert';
  const [openSuccessAlert, setOpenSuccessAlert] = useState(() => {
    const showAlert = localStorage.getItem(showSuccessAlertKey);
    return showAlert === null ? true : showAlert === 'true';
  });
  const isLocal = window.location.host.includes('localhost');
  const proxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  const setTopMessage = () => {
    if (accountInfoFormState.alreadyInstalled) {
      if (!provisionCompletionStatus) {
        setProvisionStatusMessage(
          'Secure Share setup has been initiated. You will see a status update once your share is ready to access.'
        );
      } else {
        setProvisionStatusMessage(
          'Secure Share setup is successfully installed and ready to access.'
        );
      }
    }
  };

  const fetchProvisionCompletionStatus = async () => {
    setLoadingProvisionStatus(true);
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/provision/completionstatus/${identity?.tenantId}`,
        'GET',
        undefined,
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setProvisionCompletionStatus(await response.json());
    } catch (error) {
      Connect.log(`Error fetching snowflake provision completion status: ${error}`);
      setToast({
        message: 'Error fetching snowflake provision completion status! Please refresh the page and try again later!!!',
        severity: 'error',
        keyRender: Date.now()
      });
    } finally {
      setLoadingProvisionStatus(false);
    }
  };

  const fetchAccountInfo = async () => {
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/accountinfo`,
        'GET',
        undefined,
        { 'Accept': 'application/json', 'Content-Type': 'application/json', 'zuora-tenant-id': identity?.tenantId }
      );
      const payload = await response.json();
      if (payload?.status === 500) {
        // Note(Duc): this 500 error returned in the response indicates the tenant does not have SNOWFLAKE created in tenant registry
        accountInfoFormDispatch({ type: 'loadAccountInfo', payload: {} });
      } else {
        accountInfoFormDispatch({ type: 'loadAccountInfo', payload });
      }
    } catch (error) {
      Connect.log(`Error fetching snowflake account info: ${error}`);
      setToast({
        message: 'Error fetching snowflake account info! Please refresh the page and try again later!!!',
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  const fetchRegions = async () => {
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/regions`,
        'GET',
        undefined,
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setRegions(await response.json());
    } catch (error) {
      Connect.log(`Error fetching snowflake regions: ${error}`);
      setToast({
        message: 'Error fetching snowflake regions! Please refresh the page and try again later!!!',
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  useEffect(() => {
    fetchRegions();
    fetchAccountInfo();
    fetchProvisionCompletionStatus();
  }, []);

  const provisionTenantShare = async () => {
    const {
      region,
      accountEdition: snowflakeAccountEdition
    } = accountInfoFormState;

    const tenantId = identity?.tenantId;

    const businessCritical = snowflakeAccountEdition === 'Business Critical';
    const serviceType = 'billing';
    const shareObjects = null;
    const shareLatency = 'EVERYDAY';
    const warehouse = false;

    Connect.proxyCall(
      `${proxyPath}/shares/provision`,
      'POST',
      {
        tenantId,
        serviceType,
        region,
        shareObjects,
        shareLatency,
        warehouse,
        businessCritical
      },
      { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    ).then((response) => {
      if (response.ok) {
        setProvisionStatusMessage(
          'Secure Share setup has been initiated. You will see a status update once your share is ready to access.'
        );
        setToast({
          message: 'Account information was saved and provisioning was initiated successfully.',
          severity: 'success',
          keyRender: Date.now()
        });
      } else {
        setToast({
          message: 'We encountered a problem with setting up the share. Please verify the details and reinitiate the setup.',
          severity: 'error',
          keyRender: Date.now()
        });
      }
    }).catch(() => {
      setToast({
        message: 'We encountered a problem with setting up the share. Please verify the details and reinitiate the setup.',
        severity: 'error',
        keyRender: Date.now()
      });
    });
  };

  const saveAccountInfo = async () => {
    const {
      region: snowflakeAccountRegion,
      accountName: snowflakeAccountName,
      accountEdition: snowflakeAccountEdition
    } = accountInfoFormState;

    Connect.proxyCall(
      `${proxyPath}/shares/accountinfo`,
      'POST',
      {
        snowflakeAccountRegion,
        snowflakeAccountName,
        snowflakeAccountEdition,
        installationUserFirstName: identity?.firstName || 'Admin',
        installationUserLastName: identity?.lastName || 'Admin',
        installationUserEmail: identity?.workEmail || 'admin@zuora.com'
      },
      { 'Accept': 'application/json', 'Content-Type': 'application/json', 'zuora-tenant-id': identity?.tenantId }
    ).then((response) => {
      accountInfoFormDispatch({ type: 'setValidating', payload: false });
      if (response.ok) {
        fetchAccountInfo();
        setConfirmationModalOpen(false);
        provisionTenantShare();
      } else {
        setToast({
          message: 'We encounted a problem installing your account information. Please try again later.',
          severity: 'error',
          keyRender: Date.now()
        });
      }
    }).catch(() => {
      accountInfoFormDispatch({ type: 'setValidating', payload: false });
      setToast({
        message: 'We encounted a problem installing your account information. Please try again later.',
        severity: 'error',
        keyRender: Date.now()
      });
    });
  };

  const validateAccountInfo = async () => {
    const {
      region: snowflakeAccountRegion,
      accountName: snowflakeAccountName,
      accountEdition: snowflakeAccountEdition
    } = accountInfoFormState;

    accountInfoFormDispatch({ type: 'setValidating', payload: true });
    Connect.proxyCall(
      `${proxyPath}/shares/accountinfo/validate`,
      'POST',
      {
        snowflakeAccountRegion,
        snowflakeAccountName,
        snowflakeAccountEdition,
        installationUserFirstName: identity?.firstName || 'Admin',
        installationUserLastName: identity?.lastName || 'Admin',
        installationUserEmail: identity?.workEmail || 'admin@zuora.com'
      },
      { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    ).then((response) => {
      if (response.ok) {
        saveAccountInfo();
      } else {
        accountInfoFormDispatch({ type: 'setValidating', payload: false });
        setToast({
          message: 'We encounted a problem verifying your account information. Please try again later.',
          severity: 'error',
          keyRender: Date.now()
        });
      }
    }).catch(() => {
      accountInfoFormDispatch({ type: 'setValidating', payload: false });
      setToast({
        message: 'We encounted a problem verifying your account information. Please try again later.',
        severity: 'error',
        keyRender: Date.now()
      });
    });
  };

  const getInputInstructions = (snippet: string, type: string) => {
    const message = type === 'region' ? 
    'To find this information, navigate to your Snowflake account, then run the following command:' :
    'To find the account locator, run the following command in your Snowflake account, and if you receive a validation failure, the locator differs from the account name; in that case, retrieve the locator from your Snowflake URL, which is in the format https://<account_locator>.<cloud_region>.snowflakecomputing.com/..., where the part before the first dot (.) is your Account Locator:'

    return (
      <Box sx={{ margin: '10px' }}>
        <Typography
          component="div"
          variant="body2"
          body={message}
        />
        <Box
          sx={{
            backgroundColor: designTokens.colors.lightActionSelected,
            fontFamily: 'courier',
            lineHeight: '3.5em',
            marginTop: '5px',
            paddingLeft: '10px'
          }}
        >
          <Typography>{snippet}</Typography>
        </Box>
      </Box>
    )
  };

  const getInputInstructionsWithoutCommand = (snippet) => (
    <Box sx={{ margin: '10px' }}>
      <Typography
        component="div"
        variant="body2"
        body="To find this information, navigate to your Snowflake account, then check in account details:"
      />
      <Box
        sx={{
          backgroundColor: designTokens.colors.lightActionSelected,
          fontFamily: 'courier',
          lineHeight: '3.5em',
          marginTop: '5px',
          paddingLeft: '10px'
        }}
      >
        <Typography>{snippet}</Typography>
      </Box>
    </Box>
  );

  const RegionInstructions = () =>
    getInputInstructions('SELECT CURRENT_REGION();', 'region');

  const AccountInstructions = () =>
    getInputInstructions('SELECT CURRENT_ACCOUNT();', 'account');

  const AccountEditionInstructions = () =>
    getInputInstructionsWithoutCommand(
      'For classic UI, click the account name on top right corner and check edition in account information. For Snowsight UI, click the account name in the bottom left corner and hover over the account information to get the edition.',
    );

  const hasMissingAccountInfo = () => {
    const { region, accountName, accountEdition } = accountInfoFormState;
    return !region || !accountName || !accountEdition;
  };

  const TopMessageSection = () => {
    if (accountInfoFormState.alreadyInstalled) {
      return provisionCompletionStatus ? (
        <TopMessageCompleted>
          <Alert
            dismissible
            open={openSuccessAlert}
            severity='success'
            body={provisionStatusMessage}
            dsOnClose={() => setOpenSuccessAlert(false)}
            action={
              <Button
                dsOnClick={() => {
                  setOpenSuccessAlert(false);
                  localStorage.setItem(showSuccessAlertKey, 'false');
                }}
                variant='text'
              >
                Don't Show Again
              </Button>
            }
          />
        </TopMessageCompleted>
      ) : (
        <TopMessageInProgress>
          <Alert open severity='info' body={provisionStatusMessage} />
        </TopMessageInProgress>
      );
    } else {
      return <div />;
    }
  };

  const InstallButtonSection = () => {
    setTopMessage();
    return accountInfoFormState.alreadyInstalled ? (
      <div />
    ) : (
      <div>
        <ButtonSectionStyle>
          <Button
            disabled={
              hasMissingAccountInfo() ||
              accountInfoFormState.alreadyInstalled ||
              provisionCompletionStatus
            }
            dsOnClick={() => {
              setConfirmationModalOpen(true);
            }}
          >
            Install
          </Button>
        </ButtonSectionStyle>
      </div>
    );
  };

  const InstalledBySection = () => {
    return !accountInfoFormState.alreadyInstalled ? (
      <></>
    ) : (
      <ContentPaddingStyle>
        <div>
          <Typography><b>Installed By:</b></Typography>
        </div>
        <div>
          <Typography>{`${accountInfoFormState.installerFirstName} ${accountInfoFormState.installerLastName} (${accountInfoFormState.installerEmail})`}</Typography>
        </div>
        <div>
          <Typography>{getDatetime(accountInfoFormState.installDate, zdsLocale, timezone)}</Typography>
        </div>
      </ContentPaddingStyle>
    );
  };

  const AccountInfoLoadingAnimation = () =>
    accountInfoFormState.validating ? (
      <Box
        sx={{
          marginRight: '40px',
        }}
      >
        <Spinner size="small" />
      </Box>
    ) : (
      <></>
    );

  const ModalBody = () => (
    <>
      <div>
        <Typography>By clicking "Allow & Install" below, you are allowing Zuora to transmit
          your Zuora data to the Snowflake account that you have provided.</Typography>
        <Typography>This action cannot be reverted.</Typography>
      </div>

      <ContentPaddingStyle>
        <Typography><b>Snowflake Region:</b> {accountInfoFormState.region}</Typography>
        <Typography><b>Snowflake Account Name:</b> {accountInfoFormState.accountName}</Typography>
        <Typography><b>Snowflake Account Edition:</b> {accountInfoFormState.accountEdition}</Typography>
        <Typography><b>Installed By User:</b> {identity?.firstName} {identity?.lastName} ({identity?.workEmail})</Typography>
      </ContentPaddingStyle>
    </>
  );

  const content = (
    <>
      <TopMessageSection />
      <Box sx={{ marginBottom: '10px' }}>
        <Typography>Please provide your Snowflake account information.</Typography>
      </Box>
      <Select
        required
        disabled={accountInfoFormState.alreadyInstalled}
        label="Snowflake Account Region"
        placeholder="Select One"
        value={accountInfoFormState.region || ''}
        dsOnChange={(e) => {
          accountInfoFormDispatch({
            type: 'setRegion',
            payload: (e.target.value as any) || ''
          });
        }}
      >
        {
          regions.sort().map((item) => (
            <SelectItem key={item} value={item}>
              {item}
            </SelectItem>
          ))
        }
      </Select>
      <RegionInstructions />
      <TextField
        required
        disabled={accountInfoFormState.alreadyInstalled}
        label="Snowflake Account Locator"
        dsOnChange={({ target }) => {
          accountInfoFormDispatch({
            type: 'setAccountName',
            payload: target.value
          });
        }}
        value={accountInfoFormState.accountName || ''}
      />
      <AccountInstructions />
      <Select
        required
        disabled={accountInfoFormState.alreadyInstalled}
        label="Snowflake Account Edition"
        placeholder="Select One"
        value={accountInfoFormState.accountEdition || ''}
        dsOnChange={(e) => {
          accountInfoFormDispatch({
            type: 'setAccountEdition',
            payload: (e.target.value as any) || ''
          });
        }}
      >
        <SelectItem value="Enterprise">Enterprise</SelectItem>
        <SelectItem value="Business Critical">Business Critical</SelectItem>
      </Select>
      <AccountEditionInstructions />
      <InstallButtonSection />
      <InstalledBySection />
      <Modal
        id="confimation-modal-id"
        open={confirmationModalOpen}
        header={
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <ModalTitle>
                ALLOW ZUORA TO CONNECT & TRANSMIT DATA TO SNOWFLAKE
              </ModalTitle>
              <AccountInfoLoadingAnimation />
            </Box>
          </>
        }
        body={<ModalBody />}
        footer={
          <ModalActions>
            <Button
              disabled={accountInfoFormState.validating}
              variant="outlined"
              dsOnClick={() => setConfirmationModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={accountInfoFormState.validating}
              dsOnClick={() => validateAccountInfo()}
            >
              Allow & Install
            </Button>
          </ModalActions>
        }
        dsOnClose={() => setConfirmationModalOpen(false)}
      />
    </>
  );

  return (
    <Card
      id="accountInfoSection"
      header="Snowflake Account Information"
      body={loadingProvisionStatus ? <Spinner /> : content}
      variant="outlined"
    />
  );
};

export default AccountInfo;
