import React, { useState } from 'react';
import Connect from '../../../../Connect/Connect';
import { useToast } from '../../../../ToastProvider';
import { Button, Grid, designTokens } from '@platform-ui/design-system';
import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, InputAdornment, Switch, TextField } from '@mui/material';
import { useStoreDispatch } from '../../../../Store';
import { ZDP_AUTH_SETUP } from '../action_types';

export const BasicAuth = ({
  contentDisabled = false,
  savedCreds = { username: null, password: null, token: null, sandbox: false }
}) => {
  const dispatch = useStoreDispatch();
  const [username, setUsername] = useState(savedCreds.username);
  const [password, setPassword] = useState(savedCreds.password ? '********' : '');
  const [token, setToken] = useState(savedCreds.token ? '****************' : '');
  const [showPassword, setShowPassword] = useState(false);
  const [showSecurityToken, setShowSecurityToken] = useState(false);
  const [isSandbox, setIsSandbox] = useState(savedCreds.sandbox);
  const [isEdited, setIsEdited] = useState(false);
  const [saving, setSaving] = useState(false);
  const { setToast } = useToast();
  const isLocal = window.location.host.includes('localhost');
  const appsV1CredsPath = `${isLocal ? 'http://localhost:8080' : ''}/apps/v1/setupSfdcCredential`;
  const saveBtnDisabled = contentDisabled || !isEdited;
  const hasErrors = !username || !password || !token;

  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          fullWidth
          placeholder='Username'
          label='Username'
          required
          onChange={(event) => {
            setUsername(event.target.value);
            setIsEdited(true);
          }}
          value={username}
          type='text'
          disabled={contentDisabled}
          error={!username && !contentDisabled}
          helperText={!username && !contentDisabled ? 'Salesforce User ID is required' : ' '}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          placeholder='Password'
          label='Password'
          required
          InputProps={{
            endAdornment:
              <InputAdornment position='end'>
                <Button
                  icon={showPassword ? 'visibility_off' : 'visibility'}
                  dsOnClick={() => setShowPassword(!showPassword)}
                  disabled={contentDisabled}
                />
              </InputAdornment>
          }}
          onChange={(event) => {
            setPassword(event.target.value);
            setIsEdited(true);
          }}
          value={password}
          type={showPassword ? 'text' : 'password'}
          disabled={contentDisabled}
          error={!password && !contentDisabled}
          helperText={!password && !contentDisabled ? 'Password is required' : ' '}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          placeholder='Security Token'
          label='Security Token'
          required
          InputProps={{
            endAdornment:
              <InputAdornment position='end'>
                <Button
                  icon={showSecurityToken ? 'visibility_off' : 'visibility'}
                  dsOnClick={() => setShowSecurityToken(!showSecurityToken)}
                  disabled={contentDisabled}
                />
              </InputAdornment>
          }}
          onChange={(event) => {
            setToken(event.target.value);
            setIsEdited(true);
          }}
          value={token}
          type={showSecurityToken ? 'text' : 'password'}
          disabled={contentDisabled}
          error={!token && !contentDisabled}
          helperText={!token && !contentDisabled ? 'Security Token is required' : ' '}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl component='fieldset' variant='standard' disabled={contentDisabled}>
          <FormLabel>Use Sandbox</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={isSandbox}
                onChange={(_, checked) => {
                  setIsSandbox(checked);
                  setIsEdited(true);
                }}
                disabled={contentDisabled}
              />
            }
            label=''
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Grid container item sx={{ marginTop: '10px' }} alignContent='flex-end' alignItems='center' justify='flex-end'>
          <Box sx={{ position: 'relative' }}>
            <Button
              body='Save'
              disabled={hasErrors || saving || saveBtnDisabled}
              dsOnClick={async () => {
                try {
                  setSaving(true);

                  const response = await Connect.proxyCall(
                    appsV1CredsPath,
                    'PUT',
                    {
                      username,
                      password,
                      token,
                      isSandbox: `${isSandbox}`,
                      isIncremental: 'false'
                    },
                    {
                      'Content-Type': 'application/json'
                    }
                  );

                  if (!response.ok) throw Error('Invalid credentials');

                  const { status, message } = await response.json();

                  if (status === 'Error') throw Error(message);

                  dispatch({ type: ZDP_AUTH_SETUP, payload: { authSet: true } });

                  setToast({
                    message: 'Credentials saved successfully.',
                    severity: 'success'
                  });
                } catch (err) {
                  Connect.log(err);
                  setToast({
                    message: err.message || 'Error saving credentials',
                    severity: 'error'
                  });
                } finally {
                  setSaving(false);
                }
              }}
            />
            {
              saving &&
              <CircularProgress
                size={24}
                sx={{
                  color: designTokens.colors.blue500,
                  position: 'absolute',
                  top: '50%',
                  left: '33px',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            }
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};